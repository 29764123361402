.arrow-btn{
    padding: 0;
    border: none;
    background: none;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    outline: none;
    position: relative;
    text-decoration: none;
    color: $color-black;
    &.light{
        color: $color-white;
        .arrow-btn__arrow svg,
        .arrow-btn__circle--base circle{
            stroke: $color-white;
        }
        .arrow-btn__shaft{
            background-color: $color-white;
        }
    }
    &.red-btn{
        padding: 16px 30px;
        background-color: #EC4128;
        box-shadow: 0px 16px 32px rgba(236, 65, 40, 0.3);
        color: $color-white;
        .arrow-btn__arrow svg,
        .arrow-btn__circle--base circle{
            stroke: $color-white;
        }
        .arrow-btn__shaft{
            background-color: $color-white;
        }
    }
    &__text{
        margin-right: 40px;
        display: inline-block;
        font-weight: $bold-font-weight;
        font-size: 16px;
        transition: transform .5s ease;
        letter-spacing: .06em;
        text-transform: capitalize;
        line-height: 1;
        .arrow-btn:hover &{
            transform: translateX(22px);
        }
    }
    &__shapes{
        position: relative;
        width: 32px;
        height: 32px;
    }
    &__shaft{
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%) scaleX(1);
        width: 47px;
        height: 1px;
        background-color: $color-brown;
        transform-origin: right 50%;
        transition: transform .5s ease;
        .arrow-btn:hover &{
            transform: translateY(-50%) scaleX(0);
        }
    }
    &__arrow{
        position: absolute;
        top: 12px;
        left: 13px;
        width: 5px;
        height: 8px;
        svg{
            display: block;
            width: 5px;
            height: 8px;
            stroke: #80828a;
            transition: all .6s ease;
            .arrow-btn:hover &{
                stroke: #ef3f4a;
            }
        }
    }
    &__circles{
        display: block;
        width: 32px;
        height: 32px;
        position: relative;
        circle{
            transition: all .6s ease;
        }
    }
    &__circle{
        position: absolute;
        top: 0;
        left: 0;
        &--base{
            transform: rotate(-142deg);
            transition: all .6s ease;
            opacity: .6;
            circle{
                stroke-width: 1px;
                stroke: #80828a;
                fill: none;
                stroke-dasharray: 100;
                stroke-dashoffset: 32;
            }
            .arrow-btn:hover &{
                transform: rotate(-180deg);
                circle{
                    stroke-dashoffset: 10;
                }
            }

        }
        &--fill{
            circle{
                stroke-width: 1px;
                stroke: #ef3f4a;
                fill: none;
                stroke-dasharray: 100;
                stroke-dashoffset: 100;
            }
            .arrow-btn:hover &{
                transform: rotate(-200deg);
                circle{
                    stroke-dashoffset: 10;
                }
            }
        }
    }
}


.after-form-btn{
    padding: 0;
    border: none;
    background: none;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    outline: none;
    position: relative;
    text-decoration: none;
    color: $color-black;
    justify-content: center;
    &.red-btn{
        padding: 16px 30px;
        background-color: #EC4128;
        box-shadow: 0px 16px 32px rgba(236, 65, 40, 0.3);
        color: $color-white;
        transition: background-color .3s ease;
        &:hover{
            background-color: darken(#EC4128, 10%);
        }
    }
    .after-form &{
        margin-top: 25px;
        min-width: 256px;
    }
}