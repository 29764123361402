.contact-page{
    &__section{
        &.section_bg-gradient{
            background-image: linear-gradient(to right, $color-brown 0, $color-brown 60%, $color-white 60%, $color-white 100%);
            @include r(1023){
                background-image: none;
                background-color: $color-brown;
            }
        }
        .container_lg{
            padding-top: 70px;
            padding-bottom: 70px;
        }
    }
    .intro__bg{
        background-position: center;
    }
    .intro__title{
        @include abs-center();
        font-size: 365/2560*100vw;
        text-transform: uppercase;
        letter-spacing: .05em;
        user-select: none;
        h1{
            font-size: inherit;
            letter-spacing: inherit;
        }
    }
}

.contact-page-success{
    .intro__bg{
        background-position: center;
    }
}

.contact-form{
    max-width: 30em;
    // max-width: 470px;
    color: $color-white;
    &__subtitle{
        margin-bottom: 40px;
    }
    &__field{
        margin-bottom: 25px;
        display: block;
        width: 100%;
        color: $color-white;
        input,
        textarea{
            padding: 15px 0;
            width: 100%;
            background-color: transparent;
            border: none;
            border-bottom: 2px solid;
            color: currentColor;
            &:focus{
                outline: 1px dashed rgba($color-white, .2);
            }
            @include placeholder{
                color: currentColor;
            }
        }
        textarea{
            resize: vertical;
        }
    }
    &__checkbox{
        margin-bottom: 30px;
        position: relative;
        display: block;
        width: 100%;
        input[type="checkbox"]{
            display: none;
            &:checked ~ .wpcf7-list-item-label{
                &::after{
                    opacity: 1;
                }
            }
        }
        .wpcf7-list-item-label{
            position: relative;
            padding-left: 35px;
            line-height: 1.2;


            &::before,
            &::after{
                content: '';
                position: absolute;
            }


            &::before{
                top: 0;
                left: 0;
                border: 2px solid $color-white;
                width: 1.2em;
                height: 1.2em;
            }
            &::after{
                top: 0.3em;
                left: 0.3em;
                width: 0.6em;
                height: 0.6em;
                background-color: $color-white;
                opacity: 0;
                transition: opacity $transition-base;
            }
        }
        a{
            color: $color-white;
            &:hover{
                text-decoration: none;
            }
        }
    }
}

.contact-map{
    @include r(1023){
        min-height: 450px;
    }
}


.after-form{
    margin-top: 30px;
    font-size: 18px;
    a{
        color: inherit;
        &:hover{
            text-decoration: none;
        }
    }
}