:root{
    --la-width: 230px;
    @include rIn(768,1279){
        --la-width: 15%;
    }
    @include r(767){
        --la-width: 100%;
    }
}

.latest-activities{
    position: fixed;
    width: var(--la-width);
    height: 100%;
    top: 0;
    right: 0;
    z-index: z('latest-activities');
    &:not(.is-open){
        pointer-events: none;
        // .latest-activities__overlay{
        //     opacity: 0;
        // }
        .latest-activities__inner{
            transform: translateX(100%);
        }
        .latest-activities__close{
            opacity: 0;
        }

    }
    &__overlay{
        // position: absolute;
        // z-index: 2;
        // width: 100%;
        // height: 100%;
        // top: 0;
        // left: 0;
        // background-color: rgba($color-brown, .5);
        // opacity: 1;
        // transition: opacity $transition-la;
        display: none;
    }
    &__close{
        position: absolute;
        right: 0;
        top: 0;
        width: 30px;
        height: 30px;
        z-index: 5;
        // background-color: rgba(0,0,0,.1);
        border-radius: 3px;
        cursor: pointer;
        transition: opacity $transition-la;
        opacity: 1;
        svg{
            @include abs-center();
            max-width: 40%;
            max-height: 40%;
        }
        @include r(767){
            right: 16px;
            top: 16px;
        }
    }
    &__inner{
        margin-left: auto;
        display: block;
        position: relative;
        z-index: 3;
        width: 100%;
        height: 100%;
        background-color: $color-white;
        transition: transform $transition-la;
        box-shadow: 0 0 20px 4px rgba(0,0,0,.05);
        &-scroll{
            margin-right: -17px;
            padding: 21px 41px 25px 25px;
            display: block;
            overflow-y: scroll;
            max-height: 100%;
            @include rIn(768,1023){
                padding: 21px 25px 25px 6px;
            }
        }
    }
    &__trigger{
        position: absolute;
        height: 80px;
        width: 50px;
        top: calc(50% - 40px);
        left: -56px;
        border-right: 3px solid $color-white;
        pointer-events: all;
        filter: drop-shadow(0 0px 8px rgba(0,0,0,.1));
        @include hover{
            cursor: pointer;
        }
        @include r(767){
            display: none;
        }
        &__popover{
            position: absolute;
            display: inline-block;
            white-space: nowrap;
            padding: 6px 8px;
            border-radius: 4px;
            background-color: $color-white;
            color: $color-black;
            font-size: 11px;
            line-height: 1.1;
            font-weight: $bold-font-weight;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
            transition: all .3s;
            // box-shadow: 0 0 20px 4px rgba(0,0,0,.05);
            &:not(.visible){
                opacity: 0;
                pointer-events: none;
            }
            &::before{
                content: '';
                position: absolute;
                z-index: -1;
                @include arr(5,5, $color-white, r);
                top: calc(50% - 2px);
                left: 100%;

            }
        }
    }
    &__title{
        margin-bottom: 22px;
        display: block;
        font-weight: $bold-font-weight;
        font-size: 1rem;
        line-height: 19/16*1em;
        letter-spacing: .03em;
        color: $color-brown;
        text-align: center;
        @include r(767){
            text-transform: uppercase;
            margin-top: 5px;
        }
    }
    &__list{
        @extend %reset-list;
        @include r(767){
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
        }
        li{
            display: block;
            margin-bottom: 15px;
            @include rIn(350,767){
                flex: 1 1 48%;
                max-width: 48%;
            }
            @include r(349){
                flex: 1 1 100%;
                max-width: 100%;
            }
        }
    }
    &__item{
        display: block;
        position: relative;
        border-radius: 4px;
        width: 180px;
        height: 125px;
        overflow: hidden;
        text-decoration: none;
        @include r(1279){
            width: 100%;
        }
        @include rIn(768,1279){
            height: 110px;
        }
        &:hover{
            &::after{
                opacity: 0;
            }
            .latest-activities__date{
                opacity: 1;
            }
            .latest-activities__img{
                filter: grayscale(0%);
            }

        }
        &::after{
            content: '';
            position: absolute;
            z-index: 2;
            // background-image: linear-gradient(180.9deg, #474341 1.09%, rgba(71, 67, 65, 0) 56.99%);
            // background-position: center;
            // background-repeat: no-repeat;
            // background-size: cover;
            opacity: .4;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            transition: opacity .23s ease-in-out;
        }
    }
    &__img{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        filter: grayscale(100%);
        transition: filter .23s ease-in-out;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &-mobile__header{
        padding: 20px;
        display: block;
        position: relative;
        &::before{
            content: '';
            width: 300px;
            height: 300px;
            background-image: url(../img/svg/fingerprint.svg);
            background-size: contain;
            background-repeat: no-repeat;
            bottom: 0%;
            position: absolute;
            left: 50%;
            transform: translate(-50%, -9%);
            opacity: .03;
            z-index: -1;
        }
        .logo{
            width: 100px;
            height: 100px;
            margin: 0 auto;
        }
    }
    &__info{
        padding: 10px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $color-white;
        position: relative;
        z-index: 3;
    }
    &__social{
        display: flex;
    }
    &__date{
        font-size: 12px;
        font-weight: $bold-font-weight;
        letter-spacing: 0.03em;
        opacity: 0;
        transition: opacity .23s ease-in-out;
    }
}


@include rmin(768){
    .layout,
    .footer__inner,
    .header.is-fixed{
        max-width: 100%;
        will-change: max-width;
        transition: max-width $transition-la;
    }
    .la-is-open {
        .layout,
        .footer__inner,
        .header.is-fixed{
            max-width: calc(100% - var(--la-width));
        }
    }
    .nav__list .js-open-activities,
    .latest-activities-mobile__header{
        display: none;
    }

}
@include r(767){
    body:not(.readyToOpenPopups){
        .latest-activities{
            display: none;
        }
    }
}