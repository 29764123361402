.expertise-page{

    .expertise-slider .fp-slidesNav{
        color: $color-white;
    }
    &.fp-viewing-1-1,
    &.fp-viewing-1-3,
    &.fp-viewing-1-5,
    &.fp-viewing-2-1,
    &.fp-viewing-2-3,
    &.fp-viewing-2-5{
        .expertise-slider .fp-slidesNav{
            color: $color-brown;
        }
    }
}
.expertise-slider.active{
    .expertise-slider-menu{
        opacity: 1;
        transform: translateY(0);
    }
}
.expertise-slider-menu{
    position: absolute;
    z-index: 10;
    top: 63px;
    width: 100%;
    margin: 0;
    background-color: $color-white;
    box-shadow: 0px 16px 32px rgba(71, 67, 65, 0.05);
    opacity: 0;
    transform: translateY(-100%);
    transition: opacity .4s 1s ease-in-out, transform .4s 1s ease-in-out;
    @include r(1023){
        display: none !important;
    }
    ul{
        @extend %reset-list;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    li{
        padding: 20px 30px;
        @media (min-width: 1200px) and (max-height:800px){
            padding: 5px 30px;
        }
        @media (min-width: 1024px) and (max-width: 1199px) and (max-height:800px){
            padding: 5px 15px;
        }
        @include rIn(1024,1199){
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    a{
        padding: 7px 14px;
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        align-items: center;
        text-align: right;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        color: $color-brown;
        border-radius: 16px;
        border: 1px solid transparent;
        text-decoration: none;
        transition: all $transition-base;
        @media (min-width: 1024px) and (max-height:800px){
            padding: 4px 14px;
            font-size: 10px;
        }
        &.active{
            border-color: $color-brown;
        }
        &:not(.active):hover{
            color: darken($color-brown,10%);
            border-color: rgba($color-brown, .2);
        }
    }
}


.expertise-slider{
    &.section.active{
        background-color: $color-brown;
    }
    .scroll-btn{
        display: none !important;
    }
    .fp-slidesNav{
        transform: translateY(-50%);
        top: 50%;
        right: 46px;
        left: auto !important;
        width: 50px;
        height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: $color-brown;
        @include r(1199){
            right: 10px;
        }
        &.lighten{
            color: $color-white;
        }
        ul{
            // pointer-events: none;
            li{
                &:not(:last-child){
                    margin-bottom: 35px;
                }
                display: block;
                width: 20px;
                height: 20px;
                &:hover{
                    a span,
                    a.active span{
                        width: 6px;
                        height: 6px;
                        margin: 0;
                        transform: scale(2);
                        transform-origin: 50% 50%;
                    }
                }
            }
            a{
                color: currentColor;
                span,
                &.active span{
                    margin: 0;
                    position: absolute;
                    top: calc(50% - 3px);
                    left: calc(50% - 3px);
                    width: 6px;
                    height: 6px;
                    background-color: currentColor;
                    // background-color: $color-brown;
                }

                &.active{
                    &::before,
                    &::after{
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
                &::before,
                &::after{
                    content: '';
                    position: absolute;
                    width: 20px;
                    height: 9px;
                    border: 1px solid currentColor;
                    opacity: 0;
                    transition: all $transition-base;
                }
                &::before{
                    border-bottom: 0;
                    border-radius: 50% 50% 0 0 / 100% 100% 0 0;
                    top: -1px;
                    transform: translateY(10px);
                }
                &::after{
                    border-top: 0;
                    border-radius:  0 0 50% 50% / 0 0 100% 100%;
                    bottom: -1px;
                    transform: translateY(-10px);
                }

            }
        }
    }
    .slide {
        @include r(1023){
            visibility: visible !important;
        }
        @include rmin(1024){
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            transition: opacity 1s cubic-bezier(0.6, -0.28, 0.735, 0.045);
            // &:not(.active){
            //     visibility: hidden;
            // }
            &.expertise-slide5{
                // z-index: 1;
            }
            &.expertise-slide4{
                // z-index: 2;
            }
            &.expertise-slide3{
                // z-index: 3;
            }
            &.expertise-slide2{
                // z-index: 4;
                &:not(.active){
                    .slide__top{
                        background-color: transparent;
                    }
                }
            }
            &.expertise-slide1{
                // z-index: 5;
            }
            &.expertise-slide4 .slide__bottom{
                background-color: $color-brown;
            }
            &.expertise-slide2 .slide__bottom,
            &.expertise-slide5 .slide__bottom{
                background-color: $color-green;
            }
        }
        @include r(1023){

            &.expertise-slide1,
            &.expertise-slide3{
                .slide__img{
                    &::after{
                        content: '';
                        position: absolute;
                        width: 98/320*100%;
                        height: 43/360*100%;
                        background-color: #fff;
                        bottom: 0;
                        right: 0;
                    }
                }
            }
            &.expertise-slide2{
                .slide__img{
                    &::before,
                    &::after{
                        content: '';
                        position: absolute;
                    }
                    &::before{
                        width: 98/320*100%;
                        height: 43/360*100%;
                        background-color: #fff;
                        top: 0;
                        left: 0;
                    }
                    &::after{
                        content: '';
                        position: absolute;
                        width: 74/320*100%;
                        height: 62/360*100%;
                        background-color: $color-green;
                        bottom: 0;
                        right: 0;
                    }
                }
            }

            &.expertise-slide4{
                .slide__img{
                    &::after{
                        content: '';
                        position: absolute;
                        width: 98/320*100%;
                        height: 43/360*100%;
                        background-color: #fff;
                        top: 0;
                        left: 0;
                        z-index: 1;
                    }
                }
            }
            &.expertise-slide5{
                .slide__img{
                    &::before,
                    &::after{
                        content: '';
                        position: absolute;
                    }
                    &::before{
                        width: 98/320*100%;
                        height: 43/360*100%;
                        background-color: #fff;
                        top: 0;
                        left: 0;
                    }
                    &::after{
                        content: '';
                        position: absolute;
                        width: 74/320*100%;
                        height: 62/360*100%;
                        background-color: $color-green;
                        bottom: 0;
                        right: 0;
                    }
                }
            }
        }

        &__inner{
            padding-right: 160px;
            display: flex;
            width: 100vw;
            @include rmin(1024){
                height: 100vh;
            }
            @include rIn(1024,1199){
                padding-right: 85px;
            }
            @include r(1023){
                padding-right: 0;
                flex-wrap: wrap;
            }
        }
        &__left,
        &__right{
            @extend %flex-column;
            width: 50%;
            position: relative;
            @include r(1023){
                width: 100%;
            }
        }

        &__top{
            background-color: $color-white;
            color: $color-black;
            width: 100%;
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            @extend %container_lg;
            position: relative;
            overflow: hidden;
            &__inner{
                flex: 0 0 auto;
                width: 100%;
            }
            @include rIn(1024,1199){
                padding-left: 50px;
                padding-right: 50px;
            }
            @include r(1023){
                padding-top: 40px;
                padding-bottom: 40px;
            }
            @include r(767){
                padding-top: 25px;
                padding-bottom: 25px;
            }
            @media (min-width: 1200px) and (max-height:800px){
                padding-left: 80px;
                padding-right: 80px;
            }
            @media (min-width: 1024px) and (max-height:600px){
                padding-left: 80px;
                padding-right: 80px;
                padding-top: 70px;
            }
        }
        &__subtitle{
            font-weight: $bold-font-weight;
        }
        &__num{
            position: absolute;
            font-weight: $bold-font-weight;
            font-size: 11px;
            line-height: 18px;
            transform: rotate(90deg);
            @include r(1023){
                display: none;
            }
            &::before{
                content: '';
                position: absolute;
                background-color: currentColor;
                width: 13px;
                height: 1px;
                right: calc(100% + 4px);
                top: 50%;
            }
        }
        &[data-slide_nav="light"] .slide__num{
            color: rgba($color-black, .15);
        }
        @include r(1023){
            &.expertise-slide1,
            &.expertise-slide3,
            &.expertise-slide5{
                .slide__right{
                    order: -1;
                }
            }
        }

        &.expertise-slide4 .slide__num,
        &[data-slide_nav="dark"] .slide__num{
            color: $color-white;
        }
        .slide__left > .slide__num{
            top: 10vh;
            left: 5vw;
            z-index: 1;
            @include rIn(1024,1199){
                top: 15vh;
                left: 1vw;
            }
        }
        &__bottom{
            @extend %flex-row;
            @extend %flex-auto;
            height: 33%;
            max-height: 210px;
            position: relative;
            @include rIn(1024,1199){
                height: 20%;
            }
            @include r(1023){
                display: none;
            }
            @media (min-width: 1024px) and (max-height:800px){
                height: 15%;
            }
            @media (min-width: 1024px) and (max-height:600px){
                display: none;
            }
        }
        &__decor{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            color: rgba($color-white, .3);
            @include css-lock(10,15,768,1200);
            .circle{
                width: 4.5em;
                height: 10em;
                border: 2px solid;
                border-left: 0;
                border-radius: 0 100% 100% 0/0 50% 50% 0;
                position: absolute;
                top: calc(50% - 5em);
                left: 1em;
            }
            .line{
                position: absolute;
                top: 50%;
                width: 10em;
                height: 1px;
                background-color: $color-white;
                left: 7em;
            }
        }

        &:nth-child(even){
            .slide__img{
                margin-left: auto;
            }
        }
        &__img{
            display: block;
            width: 100%;
            position: relative;
            overflow: hidden;
            @include rmin(1024){
                height: 100%;
                img{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    object-fit: cover;
                }
            }
            @include r(1023){
                background-color: $color-white;
                padding: 1px;
                display: block;
                img,video{
                    display: block;
                    width: 100%;
                    height: auto;
                    object-fit: cover;
                }
            }
        }
    }
}

.install-slider{
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    @include rmin(1200){
        width: calc((100vw - 160px) / 2);
    }
    @include rIn(1024,1199){
        width: calc((100vw - 85px) / 2);
    }
    @include r(1023){
        width: 100vw;
        height: 100vh;
        position: relative;
        img{
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            top: 0;
            left: 0;
        }
    }
    &::after{
        content: '';
        @extend %overlay;
        background-color: rgba(black,.3);
        z-index: 1;
        pointer-events: none;
    }
    .slick-list, .slick-slider{
        width: 100%;
        height: 100%;
    }
    div{
        height: 100%;
    }
    .slick-track{
        height: 100%;
    }
    .i-slide{
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
    }

    .slick-dots{
        margin: 0;
        padding: 0;
        position: absolute;
        left: 10%;
        z-index: 2;
        bottom: 30px;
        width: 80%;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        position: relative;
        list-style: none;
        &::before{
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            top: 50%;
            left: 0;
            background-color: rgba($color-white, .2);
        }
        li{
            flex: 1 1 auto;
            position: relative;
            button{
               @extend %reset-btn;
               font-size: 0;
               display: block;
               width: 100%;
               height: 1px;
               position: relative;
               background-color: transparent;
               &::before{
                   content: '';
                   position: absolute;
                   height: 100%;
                   width: 100%;
                   left: 0;
                   top: 0;
                   transform: scaleX(1);
                   transform-origin: 0 50%;
                   background-color: #fff;
                   transition: .23s ease;
                }
               &::after{
                   content: '';
                   position: absolute;
                   width: 100%;
                   height: 30px;
                   top: -15px;
               }
            }
            &:not(:last-child){
                &::after{
                    content: '';
                    position: absolute;
                    right: 0;
                    top: -2px;
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    background-color: $color-white;
                    transition: background-color .2s ease .2s;
                }
            }
            &.slick-active{
                &::after{
                    background-color: $color-white;
                }
                & ~ li{
                    button::before{
                        transform: scaleX(0);
                    }
                    &::after{
                        background-color: rgba($color-white, .2);
                    }
                }
            }
        }
    }
}