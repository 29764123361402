.footer{
    position: relative;
    z-index: 0;
    min-height: 450px;
    font-size: 16px;
    color: $color-white;
    @include r(1023){
        min-height: 385px;
    }
    &__inner{
        background: $color-black;
        width: 100%;
        &::after {
            content: '';
            background: $color-black;
            width: 100%;
            height: 100px;
            z-index: -2;
            position: absolute;
            bottom: 99%;
        }
        @include rmin(768){
            position: fixed;
            bottom: 0;
            left: 0;
            z-index: -1;
        }
    }
    &-top{
        padding: 80px 65px 50px;
        @extend %flex-row;
        align-items: center;
        justify-content: space-between;
        @include r(1023){
            padding: 34px 16px 35px;
        }
        @include r(767){
            flex-wrap: wrap;
            text-align: center;
        }
        &__address{
            // max-width: 200px;
            &_doubled{
                @include rmin(350){
                    display: flex;
                    justify-content: center;

                }
                @include r(767){
                    flex-wrap: wrap;
                    .part1,
                    .part2{
                        flex: 1 1 100%;
                        max-width: 100%;
                    }
                }
                @include rmin(768){
                    .part1{
                        margin-right: 20px;
                    }
                }
            }
            @include r(767){
                flex: 1 1 100%;
                max-width: 100%;
            }

        }
        &__logo{
            @include r(767){
                flex: 1 1 100%;
                max-width: 100%;
                order: -1;
            }

            .logo{
                width: 205/1366*100vw;
                height: 205/1366*100vw;
                max-width: 205px;
                max-height: 205px;
                @include r(767){
                    width: 75px;
                    height: 75px;
                    margin: 0 auto;
                }
            }
            &_with-doubled{
                @include rIn(768,1199){
                    display: none;
                }
                @include rmin(1200){
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
        &__contact{
            text-align: right;
            @include r(767){
                flex: 1 1 100%;
                max-width: 100%;
            }
            .line{
                min-width: 200px;
                @extend %flex-row;
                align-items: center;
                &_top{
                    display: block;
                    width: 100%;
                    text-align: center;
                    @include rmin(768){
                        text-align: right;
                    }
                }
                @include r(767){
                    justify-content: center;
                }
                .left{
                    flex: 0 0 60px;
                    @include r(767){
                        flex: 0 0 auto;
                    }
                }
                .right{
                    flex: 1 0 140px;
                    @include r(767){
                        padding-left: 15px;
                        flex: 0 0 auto;
                    }
                }
            }
            &__socials{
                list-style: none;
                padding: 0;
                margin: 15px 0 0;
                @include r(767){
                    text-align: center;
                }
                li{
                    display: inline-block;
                    & + li{
                        margin-left: 15px;
                    }
                }
                svg{
                    fill: $color-white;
                    width: 16px;
                    height: 16px;
                }
            }
            a{
                color: $color-white;
                text-decoration: none;
                &:hover{
                    text-decoration: underline;
                }
            }

        }
        .decor-circle{
            top: 40px;
            right: 0;
            transform: rotate(90deg) translateY(-50%);
        }
    }
    .footer-small-title{
        font-size: 18px;
    }
    &-bottom{
        padding: 0 65px;
        display: block;
        width: 100%;
        position: relative;
        &__inner{
            // padding: 50px 0;
            padding: 40px 0;
            @extend %flex-row;
            border-top: 1px solid rgba($color-brown, .12);
            font-size: 14px;
            color: rgba($color-white, .7);
            line-height: 22/14*1em;
            justify-content: center;
            @include r(767){
                flex-wrap: wrap;
                text-align: center;
            }
            a{
                color: currentColor;
                text-decoration: underline;
                text-underline-position: below;
                & + a{
                    margin-left: 15px;
                }
            }
        }
        &__divider{
            margin: 0 15px;
            @include r(767){
                display: none;
            }
        }
        .decor-circle{
            left: 0;
            top: 0;
            transform: translate(-50%, -50%);
        }

    }
}