// Decor
.decor-fingerprint {
    width: 475/1366*100%;
    color: $color-black;
    opacity: 0.03;
    @include aspect-ratio(543, 518, true);
    position: absolute;

    &_tr {
        top: 0;
        right: 0;
        transform: translate(50%, -50%);
    }

    &_br {
        bottom: 0;
        right: 0;
        transform: translate(50%, 50%);
    }

    &_light {
        color: $color-white;
        opacity: 0.08;
    }

    svg {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
    }
    &_portfolio-inner{
        position: absolute;
        width: 474/1366*100%;
        left: 0;
        transform: translateX(-50%);
        @include css-lock(50,100,450,1200, top);
        z-index: -1;
    }
    &_contact,
    &_about{
        position: absolute;
        left: -20vw;
        top: 70px;
        width: 40vw;
        z-index: -1;
    }
    &_about{
        z-index: 2;
    }
    &_craftsmen{
        position: absolute;
        width: 40vw;
        z-index: -1;
        opacity: .3;
        &.top{
            right: -20vw;
            top: -5vw;
        }
        &.bottom{
            left: -20vw;
            bottom: -5vw;
        }
    }
}

.decor-circle{
    width: 116px;
    height: 116px;
    position: absolute;
    svg{
        fill: rgba($color-black, .5);
        width: 100%;
        height: 100%;
    }
}