@import "../css/jquery.fullpage.min.css";
// @import "./node_modules/slick-carousel/slick/slick.scss";
@import "../../../node_modules/slick-carousel/slick/slick.scss";
@import "utils/vars";
@import "utils/mixins";
@import "utils/fonts";
@import "utils/normalize";
@import "../../../node_modules/bootstrap-4-grid/scss/grid.scss";

html, body {
    height: 100%;
}

html {
    font-size: $main-font-size;
    line-height: $main-line-height;
}

body {
    margin: 0;
    padding: 0;
    font-family: $main-font;
    font-size: $main-font-size;
    line-height: $main-line-height;
    font-weight: $main-font-weight;
    color: $color-black;
    @extend %antialias;
    width: 100%;
    position: relative;
    overflow-x: hidden;
    @include rIn(1200,1599){
        font-size: 16/1366*100vw;
    }
    @include rmin(1600){
        font-size: 14/1366*100vw;
    }
}

*, *:before, *:after {
    box-sizing: border-box;
}

img, svg {
    max-width: 100%;
}

svg {
    fill: currentColor;
    max-height: 100%;
}

button, a {
    cursor: pointer;
}

.layout {
    position: relative;
    overflow: hidden;
    &__main{
        position: relative;
    }
}

%container {
    padding-left: var(--g-padding-x);
    padding-right: var(--g-padding-x);
    @include r(1023){
        padding-left: 15px;
        padding-right: 15px;
    }
}

%container_lg {
    padding-left: var(--g-padding-x);
    padding-right: var(--g-padding-x);
    @include r(1023){
        padding-left: 16px;
        padding-right: 16px;
    }
}

.container {
    @extend %container;
}

.container_lg {
    @extend %container_lg;
}

// Components
@import "components";

// Utils
@import "utils/utils";


.experience-slide {
    position: relative;

    &__container {
        @extend %flex-row;
        height: 100vh;
        position: relative;
    }

    &__col {
        @extend %flex-column;
        width: 50%;
        position: relative;
        @include r(1023){
            width: 100%;
            height: 100%;
            &:nth-child(2){
                position: absolute;
                z-index: 1;
            }
        }
    }

    &__body {
        background-color: #BCE1E2;
        color: $color-white;
        width: 100%;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @extend %container_lg;
        position: relative;
        overflow: hidden;
        @include r(1023){
            padding-top: 70px;
            background-color: transparent;

        }
    }

    &__src{
        display: none;
    }

    &__caption {
        flex: 0 0 auto;
        width: 100%;
    }

    &__text {
        font-weight: $bold-font-weight;

        p {
            margin: 12/16*1em 0 0;

            &:first-child {
                margin-top: 0;
            }
        }
    }

    &__num {
        position: absolute;
        bottom: 0;
        flex: 0 0 auto;
        font-weight: $bold-font-weight;
        margin-bottom: 56/558*100%;
        @include r(1023){
            display: none;
        }

        &::before {
            content: "";
            display: inline-block;
            vertical-align: middle;
            width: 12px;
            height: 2px;
            border-bottom: 2px solid;
            margin-right: 8px;
        }
    }

    &__footer {
        @extend %flex-row;
        @extend %flex-auto;
        height: 33%;
        max-height: 210px;
        @include r(1023){
            position: static;
            height: 90px;
        }
    }

    &__link {
        @extend %flex-row;
        align-items: center;
        @extend %flex-stretch;
        &.arrow-btn{
            padding-left: var(--g-padding-x);
            @include r(1023){
                padding-left: 15px;
                color: $color-white;
                .arrow-btn__arrow svg,
                .arrow-btn__circle--base circle{
                    stroke: $color-white;
                }
                .arrow-btn__shaft{
                    background-color: $color-white;
                }
            }
        }
    }

    &__arrows {
        @extend %flex-row;
        align-items: center;
        justify-content: center;
        @extend %flex-auto;
        background-color: $color-light;
        width: 268/683*100%;
        @include r(1023){
            position: absolute;
            top: 80px;
            right: 15px;
            width: auto;
            background-color: transparent;
        }
        @include r(767){
            top: 25px;
        }

    }
    &__btn{
        @extend %reset-btn;
        height: 100%;
        color: $color-black;
        display: inline-flex;
        width: 50%;
        align-items: center;
        @include r(1023){
            color: $color-white;
        }
        &.disabled,
        &:disabled{
            color: rgba($color-black, .1);
        }
        &--prev{
            svg{
                margin-right: 30px;
                margin-left: auto;
            }
            &:hover:not(.disabled):not(:disabled) svg{
                transform: translateX(-10px);
            }
        }
        &--next{
            svg{
                margin-left: 30px;
                margin-right: auto;
                @include r(1023){
                    margin-left: 0;
                }

            }
            &:hover:not(.disabled):not(:disabled) svg{
                transform: translateX(10px);
            }
        }
        svg{
            stroke: currentColor;
            width: 10px;
            height: 20px;
            transition: transform .5s ease;
            @include r(1023){
                width: 30px;
                height: 28px;
            }
        }
    }


    &__img {
        @extend %overlay;
        @extend %bg-cover;
        @extend %container;
        padding-top: 56px;
        padding-bottom: 56px;
        color: $color-white;

        /********** temp ?*************/
        pointer-events: none;

        @include r(1023){
            // padding-top: 70px;
            &::after{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                z-index: 0;
                pointer-events: none;
                background: linear-gradient(180deg, rgba(71, 67, 65, 0) 0%, #474341 100%);
                opacity: 0.4;
            }
        }
        @include r(767){
            padding-top: 15px;
        }

    }

    &__img-num {
        display: flex;
        align-items: flex-end;
        position: relative;
        overflow: hidden;
        white-space: nowrap;
        z-index: 2;

        @include r(1023){
            width: 50%;
        }
        @include r(767){
            padding-bottom: 10px;
        }
        span {
            display: block;
            position: relative;
            line-height: 1;
            // left: -24px;
            // left: -0.3em;
            &[id="sliderExpNum2"]{
                margin-bottom: -9px;
                @include css-lock(50,90,450,1200);
                font-weight: 300;
                letter-spacing: 0.03em;
            }
            &.experience-slide__divider{
                @include css-lock(27,45,450,1200);

            }
            &.experience-slide__count{
                margin-bottom: -0.07em;
                margin-left: .1em;
                @include css-lock(28,55,450,1200);
            }
            // &::after {
            //     content: "";
            //     display: inline-block;
            //     vertical-align: middle;
            //     width: .62em;
            //     height: 1px;
            //     border-bottom: 1px solid;
            //     margin-top: -14/90*1em;
            //     margin-left: 0.3em;
            // }
        }
    }

    &-canvas{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        overflow: hidden;
        canvas{
            background-color: rgba(188, 225, 226, 1);
            transform-origin: 50% 50%;
        }
    }
    &-placeholder{
        @extend %overlay;
        width: 100%;
        height: 100%;
        z-index: 0;
        overflow: hidden;
        object-fit: cover;
    }
}
.about{
    &-section{
        @include r(1023){
            padding-bottom: 40px;
        }
        .container_lg{
            max-width: none;
            padding-left: 0;
            @include r(767){
                padding-left: 16px;
            }
        }
        .decor-circle{
            left: -28px;
            bottom: 25px;
        }
        &_order{
            .container_lg{
                padding-right: 0;
                padding-left: var(--g-padding-x);
                width: 100%;
                @include r(1023){
                    padding-left: 15px;
                    padding-right: 15px;
                }
            }
            .right{
                order: 1;
            }
            .left{
                order: 2;
            }
        }
    }
    display: flex;
    flex-flow: row wrap;

    .left{
        width: 100%;
        max-width: 806/(806+560)*100%;
        height: auto;
        min-height: 500px;
        position: relative;
        @include r(1023){
            flex: 1 1 100%;
            max-width: 100%;
        }
        @include r(767){
            min-height: 300px;
        }
    }
    &__img{
        position: absolute;
        &--base{
            width: 164%;
            max-width: initial;
            top: 50%;
            transform: translateY(-50%) translateX(4%);
            @include r(767){
                width: 200%;
                transform: translateY(-50%) translateX(0%);
            }
        }
        &--grid{
            width: 92%;
            top: 50%;
            transform: translateY(-55%) translateX(6%);
            left: -5%;
        }
    }
    .right{
        flex: 1;
        @include r(1023){
            padding: 0 45px 0 15px;
        }
        @include r(767){
            padding: 0;
        }
        &__title{
            position: relative;
            &::after{
                content: '';
                position: absolute;
                width: 46px;
                height: 1px;
                background-color: $color-black;
                top: 50%;
                left: 100%;
            }
        }
    }
    .arrow-btn{
        margin-top: 20px;
    }

}

.started{
    background-image: url(../img/action/bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    text-align: center;
    @include r(1023){
        min-height: 500px;
    }
    @include retina{
        background-image: url(../img/action/bg@2x.jpg);
    }
    @include r(767){
        padding-left: 15px;
        padding-right: 15px;
        &,
        & .fp-tableCell{
            height: auto !important;
        }
    }
    &__title{
        margin-bottom: 30px;
        color: $color-white;
        font-weight: $main-font-weight;
        line-height: 1.3;
        // @include css-lock(22,48,768,1200);
        @include rmin(1200){
            font-size: 48/1366*100vw;
        }
        @include r(1199){
            @include css-lock(22,48,768,1200);
        }
        strong{
            display: block;
            font-weight: 700;
            // @include css-lock(36,48,768,1200);
            @include rmin(1200){
                font-size: 48/1366*100vw;
            }
            @include r(1199){
                @include css-lock(36,48,768,1200);
            }
            @include r(767){
                margin-bottom: 20px;
            }
        }
    }
}
.color-gray{
    color: $color-brown;
}
.section{
    &:not(.footer) {
        position: relative;
        z-index: 1;
        background-color: $color-white;
        &.fp-section.active{
            z-index: 2;
        }
    }
    &_full{
        @extend %container_full;
    }
}

.experience-slide-canvas{
    canvas{
        position: absolute;
    }
}

.static-page__inner {
    .container_lg{
        padding-top: 90px;
        padding-bottom: 90px;
        @include r(1023){
            padding-top: 45px;
            padding-bottom: 45px;
        }
    }
}


/*****************************
        FONT FIX
******************************/
body{
    &.macos{

    }
}

// whithout fullpage
.main-page,
.about-page{
    .about-section,
    .experience-slide,
    .partners-section{
        @include rmin(1024){
            overflow: hidden;
            min-height: 100vh;
        }
    }
    .partners-section{
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        min-height: 100vh;
        @include rmin(768){
            padding-top: 65px;
            padding-bottom: 65px;
        }
        @include rmin(1920){
            .container_lg{
                margin: 0 auto;
                max-width: 70%;
            }
        }
    }
    .about-section,
    .partners-section{
        @include rmin(1024){
            display: flex;
            flex-flow: column wrap;
            justify-content: center;
        }
    }
    .experience-slide__container{
        @include r(767){
            height: 600px;
        }
    }
}

// author on photo
[data-author]{
    &::after{
        content: attr(data-author);
        position: absolute;
        color: $color-white;
        @include css-lock(12,20,450,1920);
        bottom: 1em;
        left: 1em;
        line-height: 1;
        @include r(767){
            bottom: auto;
            left: 1em;
            top: calc(54.5vw - 2em);
        }
    }
}

