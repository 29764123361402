// Font
// @import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@400;500;700&display=swap');
// $main-font: 'Noto Serif JP', serif;
$main-font: 'ITC Lubalin Graph Std', serif;

// Size
$main-font-size: 16px;
$main-font-weight: 400;
$bold-font-weight: 600;
$main-line-height: 26/16;


// general paddings
:root{
    // --g-padding-x: 68px;
    --g-padding-x: 45px;
}

// Breakpoints
$breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 1024px,
        xl: 1200px
);

// Colors
$color-black: #474341;
$color-brown: #CCBFB7;
$color-brown-light: #F5F4F4;
$color-green: #BCE1E2;
$color-white: #FFFFFF;
$color-light: #FBFBFB;

// Z-indexes
$z-indexes: (
        "modal",
        "latest-activities",
        "header",
        "wrapper",
        "footer"
);

// Transitions
$transition-base: 0.4s cubic-bezier(.23, 1, .32, 1);

//transition last activities
$transition-la: .6s cubic-bezier(0.08, 1.00, 0.21, 1.00);