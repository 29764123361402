.project{
    position: relative;
    color: $color-white;
    height: 100vh;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    @media (min-width:1024px) and (max-height:700px){
        min-height: 700px;
    }
    @include rmin(1660){
        justify-content: center;
        .project__info.p-info{
            min-width: 40%;
        }
    }
    &__bg{
        @extend %overlay;
        z-index: -1;
        overflow: hidden;
        &::after{
            content: '';
            @extend %overlay;
            background-color: rgba(0,0,0,.4);
        }
        &-slider{
            @extend %overlay;
            &-src{
                display: none;
            }
            canvas{
                position: absolute;
            }
        }
    }
    &__header{
        padding: 85px var(--g-padding-x) 30px var(--g-padding-x);
        font-size: 14px;
        letter-spacing: 0.16em;
        font-weight: $bold-font-weight;
        @extend %flex-row;
        justify-content: space-between;
        @include r(1023){
            padding: 85px 16px 30px 16px;
        }

        .year{
            text-transform: uppercase;
            display: inline-block;
            vertical-align: middle;
            perspective: 100px;
            position: relative;
            span{
                transform-style: preserve-3d;
                display: inline-block;
            }
            &::after{
                margin-left: 15px;
                content: '';
                width: 55px;
                height: 1px;
                background-color: $color-white;
                display: inline-block;
                vertical-align: middle;
            }
        }

        @include r(767){
            opacity: 0;
            pointer-events: none;
        }
    }
    .btn-all-project{
        color: $color-white;
        display: inline-block;
        vertical-align: middle;
        text-decoration: none;
        &_mobile{
            @include rmin(768){
                display: none;
            }
            @include r(767){
                text-align: center;
                position: absolute;
                bottom: 40px;
                width: auto;
                left: 50%;
                transform: translateX(-50%);
            }
        }
        svg{
            margin-left: 15px;
            width: 17px;
            height: 17px;
            fill: currentColor;
            display: inline-block;
            vertical-align: middle;
        }
    }
    &__body{
        padding: 35px 0 35px var(--g-padding-x);
        @extend %flex-row;
        font-weight: $bold-font-weight;
        align-items: center;
        justify-content: flex-start;
        @include r(1023){
            padding: 30px 15px;
        }
    }
    &__footer{
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 50px var(--g-padding-x) 35px;
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        @include r(767){
            pointer-events: all;
            margin-top: 20px;
            padding: 0;
            position: static;
            left: auto;
            top: auto;
            right: auto;
            width: auto;
        }
    }
    &__dots{
        flex: 1;
        pointer-events: none;
        .slick-dots{
            margin: 0;
            padding: 0;
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            position: relative;
            list-style: none;
            &::before{
                content: '';
                position: absolute;
                width: 100%;
                height: 1px;
                top: 50%;
                left: 0;
                background-color: rgba($color-white, .2);
            }
            li{
                flex: 1 1 auto;
                position: relative;
                button{
                   @extend %reset-btn;
                   font-size: 0;
                   display: block;
                   width: 100%;
                   height: 1px;
                   background-color: #fff;
                   transition: .23s ease;
                   transform: scaleX(1);
                   transform-origin: 0 50%;
                }
                &:not(:last-child){
                    &::after{
                        content: '';
                        position: absolute;
                        right: 0;
                        top: -2px;
                        width: 5px;
                        height: 5px;
                        border-radius: 50%;
                        background-color: $color-white;
                        transition: background-color .2s ease .2s;
                    }
                }
                &.slick-active{
                    &::after{
                        background-color: $color-white;
                    }
                    & ~ li{
                        button{
                            transform: scaleX(0);
                        }
                        &::after{
                            background-color: rgba($color-white, .2);
                        }
                    }
                }
            }
        }
    }
    &__slider{

        &__btn {
            background-color: transparent;
            border: none;
            width: 50px;
            height: 50px;
            @include r(767){
                width: auto;
                height: auto;
            }
            &.disabled{
                pointer-events: none;
            }
            &:focus{
                outline: none;
            }
            svg{
                stroke: #ffffff;
                width: 8px;
                height: 16px;
                @include r(767){
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }

}

.p-info{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    height: 39vw;
    position: relative;
    max-width: 580px;
    width: 100%;
    padding-right: 100px;
    @include r(1023){
        position: absolute;
        padding: 0;
        top: 50%;
        transform: translateY(-50%);
        max-width: 65%;
        z-index: 2;
        pointer-events: none;
    }
    @include r(767){
        max-width: 90%;
    }
    .decor-circle{
        top: -50px;
        left: -50px;
        z-index: -1;
        svg{
            fill: rgba($color-white, .1);
        }
    }
    &__item{
        position: relative;
        perspective: 300px;
        @include rIn(768,1023){
            padding-left: 40px;
        }

        // @include r(767){
        //     min-height: 55vh;
        //     display: flex;
        //     flex-flow: column nowrap;
        // }
    }
    &__year-mobile{
        margin-bottom: 26px;
        font-size: 12px;
        @include rmin(768){
            display: none;
        }
    }
    &__name{
        margin-bottom: 20px;
        font-size: 14px;
        letter-spacing: 0.16em;
        text-transform: uppercase;
        @include r(767){
            margin-bottom: 10px;
            font-size: 12px;
            font-weight: $main-font-weight;
        }
    }
    &__title{
        margin-bottom: 20px;
        // @include css-lock(24,48,768,1200);
        line-height: 1.3;
        letter-spacing: 0.02em;
        transform-style: preserve-3d;
        @include rmin(1200){
            font-size: 48/1366*100vw;
        }
        @include r(1199){
            @include css-lock(24,48,768,1200);
        }
    }
    &__description{
        font-size: 16px;
        transform-style: preserve-3d;
        @include r(767){
            font-size: 14px;
        }
        @include rmin(1200){
            font-size: 16/1366*100vw;
        }
    }
    &__btn{
        margin-top: 60px;
        pointer-events: all;
        // @include r(767){
        //     display: none;
        // }
    }
}
.p-slider{
    @extend %flex-row;
    max-width: 64vw;
    width: 100%;
    // transform: translateX(230px);
    overflow: hidden;
    @media (min-width: 1500px) and (min-height: 700px){
        transform: scale(1.2);
        transform-origin: 0 100%;
    }
    @media (min-width: 1024px) and (max-width: 1499px) and (min-height: 700px){
        transform: scale(1.2);
        transform-origin: 0 50%;
    }
    @media (min-width: 1024px) and (max-width:1199px) and (min-height: 700px){
        transform: scale(1.4);
    }
    @include r(1023){
        max-width: 100%;
        max-height: 60vh;
        padding-top: 5vmin;
        padding-bottom: 5vmin;
    }
    @include r(767){
        max-height: 80vh;
        opacity: 0 !important;
    }
    .slick-list{
        overflow: visible !important;
        @include rmin(1024){
            padding: 40px 0px 40px 40px;
            max-width: 22vw;
        }
        @include r(1023){
            max-width: 70%;
            z-index: 1;
        }
        @include r(767){
            max-width: 90%;
        }
    }
    .slick-slide{
        &:focus{
            outline: none;
        }
        a{
            @extend %overlay;
            width: 100%;
            height: 100%;
            z-index: 2;
            display: none;
        }
        &.slick-active{
            @include rmin(768){
                a{
                    display: block;
                }
            }
        }
        @include r(1023){
            & > div{
                display: flex;
                height: 100%;
            }
            .slick-track{
                height: 100%;
            }
        }
    }
    @include r(1023){
        .slick-track{
            height: 100%;
        }
    }
    &__item{
        margin: 0;
        width: 100%;
        border: 15px solid transparent;
        transition: transform .23s ease;
        position: relative;
        cursor: pointer;

        &::before{
            content: '';
            display: block;
            width: 100%;
            padding-bottom: 148%;
        }
        @include r(1023){
            // padding-top: 70px;
            &::after{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                z-index: 0;
                pointer-events: none;
                background: linear-gradient(180deg, rgba(71, 67, 65, 0) 0%, #474341 100%);
                opacity: 0.4;
            }
        }
        img{
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
            @include r(1023){
                // width: 120%;
                // margin-left: -10%;
                max-width: none;
            }
        }



    }
    .slick-current .p-slider__item{

        transform: scale(1.2) translateX(-26px);
        transform-origin: 50% 50%;
        @include rIn(1024,1199){
            transform: scale(1.4) translateX(-26px);
        }
        @include r(1023){
            transform: scaleY(1.2) translateX(0);
            width: 100%;
            display: inline-block;
            overflow: hidden;
            img{
                transform: scaleX(1.2);
            }
        }
    }

}