.pm{
    &__grid{
        display: block;
        width: 100%;
    }
    &__item{
        display: inline-block;
        width: auto;
        height: auto;

        // overflow: hidden;
        position: relative;
        font-size: 24px;

        @include rmin(1024){
            &{
                // overflow: hidden;
                // max-width: 300px;

            }
            &:hover{
                z-index: 100;
                .pm__bg{
                    box-shadow: 0px 0px 150px #999;
                    transform: scale3d(1.2,1.2,1)
                }
            }
        }
    }
    &__bg{
        background-color: $color-black;
        transition: all 2s ease-in-out;
        backface-visibility: hidden;
        display: flex;
        transition: all 200ms linear ;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            opacity: .8;

        }
    }
    &__link{
        @extend %overlay;
        span{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background-color: $color-white;
            text-transform: uppercase;
            text-decoration: none;
            font-weight: $bold-font-weight;
            line-height: 62px;
            color: $color-black;
            letter-spacing: 0.2em;
            font-size: 10px;
            display: block;
            text-align: center;
            transition: opacity $transition-base;
            z-index: 1;
        }
        // @include rmin(1024){
        opacity: 0;
        &:hover{
            opacity: 1;
            // & ~ .pm__title{
            //     transform: scale(1.2);
            //     color: darken($color-white,15%);
            // }
            &::before{
                animation: pulsingOnHover 30s cubic-bezier(.175,.885,.32,1.275) 0s infinite forwards;

            }
        }
        // }
        &::before{
            content: '';
            position: absolute;
            // width: 120%;
            // height: 120%;
            width: 72px;
            height: 72px;
            transition: all $transition-base;
            transition-delay: .2s;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            transform-origin: 50% 50%;
            background-image: url(/assets/img/portfolio/ellipse.svg);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;

        }
        &::after{
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);

        }

    }
    // &__title{
    //     position: absolute;
    //     max-width: 100%;
    //     left: 1.3em;
    //     bottom: 24px;
    //     color: $color-white;
    //     line-height: 1.2;
    //     letter-spacing: 0.03em;
    //     font-weight: $bold-font-weight;
    //     z-index: 0;
    //     transform-origin: 0 100%;
    //     transition: all 5s cubic-bezier(0.075, 0.82, 0.165, 1);
    //     @include r(767){
    //         left: 15px;
    //         bottom: 30px;
    //     }
    //     @include rmin(1024){
    //         display: none;
    //     }
    //     .pm__title-uptext{
    //         margin-bottom: 10px;
    //         font-size: .58em;
    //     }
    // }
}

.portfolio-inner-page{
    .intro__content{
        height: 85vh;
    }
    &__section{
        .container_lg{
            padding-top: 90px;
            padding-bottom: 90px;
            @include rIn(768,1023){
                padding-top: 45px;
                padding-bottom: 45px;
            }
            @include r(767){
                padding-top: 30px;
                padding-bottom: 0;
            }
        }
        &_full{
            @extend %container_full;
            @include r(767){
                @include aspect-ratio(100,70,true);
                min-height: auto;
            }
        }
        &_prev-next{
            @include r(767){
                &::before{
                    content: none;
                }
            }
            .part{
                position: relative;
                display: flex;
                flex-flow: column wrap;
                align-items: center;
                justify-content: center;

                @include r(1023){
                    flex: 1 1 100%;
                    max-width: 100%;
                    min-height: 50vh;
                }
                @include rmin(1024){
                    flex: 1 1 50%;
                    max-width: 50%;
                    height: 100vh;
                }
                .opacity-text{
                    @include rmin(1024){
                        font-size: 60/1366*100vw;
                    }
                }
            }
        }
        .opacity-text{
            font-size: 96/1366*100vw;
            color: transparent;
            span{
                display: none;
            }
            @supports (-webkit-text-stroke: 1px blue) {
                @include rmin(768){
                    &.opacity-text_prev{
                        span::before{
                            content: 'Previous project';
                        }
                    }
                    &.opacity-text_next{
                        span::before{
                            content: 'Next project';
                        }
                    }
                }
                span{
                    font-weight: 900;
                    display: block;
                    -webkit-text-stroke: 1.5px $color-white;
                    transition: all $transition-base;
                    position: relative;
                    display: inline-block;
                    @include r(767){
                        font-size: 55px;
                        line-height: 1;
                        margin-bottom: 20px;
                    }
                    @include rmin(768){
                        white-space: nowrap;
                        @supports (-webkit-clip-path: polygon(0 0, 0 0, 0 100%, 0 100%)) or (clip-path: polygon(0 0, 0 0, 0 100%, 0 100%)){
                            &::before{
                                content: '';
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                font-size: inherit;
                                -webkit-text-stroke: 2.5px $color-white;
                                clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
                                transition: all $transition-base;
                            }

                        }
                    }
                }
                img{
                    display: none;
                }
            }
        }
    }
    &__bg{
        @extend %overlay;
        background-size: cover;
        background-position: 100% 50%;
        background-repeat: no-repeat;
        transition: background-position $transition-la;
    }
    &__title{
        margin-bottom: 10px;
        font-weight: $bold-font-weight;
        font-size: 32px;
        line-height: 38/32*1em;
        letter-spacing: 0.03em;
        color: $color-black;
    }

    &__nextproject{
        margin: 0 auto;
        display: block;
        width: 630px;
        max-width: 90%;
        // position: relative;
        z-index: 1;
        text-align: center;
        text-decoration: none;
        transition: all $transition-base;
        position: static;
        &::before{
            content: '';
            @extend %overlay;
            z-index: -1;
            background-color: rgba($color-black,.4);
        }
        @supports (-webkit-text-stroke: 1px blue) {
            width: auto;
        }
        @include r(767){
            position: absolute;
            max-width: 100%;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &:hover{
            opacity: .6;
            @supports (-webkit-text-stroke: 1px blue) {
                opacity: 1;
                .opacity-text{
                    span{
                        -webkit-text-stroke: 2.5px $color-white;
                        @supports (-webkit-clip-path: polygon(0 0, 0 0, 0 100%, 0 100%)) or (clip-path: polygon(0 0, 0 0, 0 100%, 0 100%)){
                            -webkit-text-stroke: 1.5px $color-white;
                            @include rmin(768){
                                &::before{
                                    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
                                }
                            }
                        }
                    }
                }
            }
        }
        img{
            width: 100%;
            height: auto;
            display: inline-block;
        }
        &__title{
            font-weight: $bold-font-weight;
            font-size: 32px;
            line-height: 38/32*1em;
            text-align: center;
            letter-spacing: 0.03em;
            color: $color-white;
        }

    }
}
.aspect-youtube{
    @include aspect-ratio(1146,680,true);
    iframe{
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }
    &.show iframe{
        z-index: 6;
    }
    .overlay{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        display: flex;
        flex-flow: column wrap;
        &__icon{
            width: 85px;
            height: 85px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            z-index: 4;
            @include r(767){
                transform: scale(0.5) translate(-50%,-50%);
                transform-origin: 0% 0%;
            }
        }
        &__bg{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 3;
            background-size: cover;
            &.show{

            }
        }
    }

}
.bg-gray{
    background-color: $color-light;
}

.img-animation{
    margin-right: auto;
    display: block;
    width: 100%;
    overflow: hidden;
    position: relative;
    &__wrapper{
        position: relative;
    }
    img{
        position: relative;
        z-index: 1;
        width: 100%;
    }
    &__overlay{
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: $color-brown-light;
    }
    // &[data-vec="toLeft"]{
    //     margin-right: auto;
    // }
    // &[data-vec="toRight"]{
    //     margin-left: auto;
    // }
}

.portfolio-masonry{
    &,
    .fp-tableCell,
    .fp-scrollable{
        @include r(1023){
            height: auto !important;
        }
    }
}

//new version
.img-animation__wrapper{
    .img-animation__overlay{
        width: 0;
        opacity: 0;
    }
    .img-animation{
        opacity: 0;
    }
    img{
        max-width: none;
    }
}






.pm__container{
    @include clearfix;
    padding: 10px;
}

.mas-grid{
    @include rmin(1220){
        min-height: 600px;
    }
    .pm__item{
        margin-bottom: 10px;
    }
    .pm__item,
    .mas-grid-sizer{
        width: 100%;
        @include rIn(400,599){
            width: calc((100% - 10px)/2);
        }
        @include rIn(600,739){
            width: calc((100% - 20px)/3);
        }
        @include rIn(740,979){
            width: calc((100% - 30px)/4);
        }
        @include rIn(980,1219){
            width: calc((100% - 40px)/5);
        }
        @include rmin(1220){
            width: calc((100% - 50px)/6);
        }
    }
}

