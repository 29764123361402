// Intro
.intro {
    position: relative;
    overflow: hidden;
    color: $color-white;

    &__bg {
        @extend %overlay;
        background-size: cover;
        background-position: 100% 50%;
        background-repeat: no-repeat;
        transition: background-position $transition-la;

        &::before {
            content: "";
            @extend %overlay;
            background: $color-black;
            opacity: 0.1;
            .portfolio-page &,
            .portfolio-inner-page &{
                opacity: 0.3;
            }
        }
        &_video{
            background-color: rgba(black, .4);
            overflow: hidden;
            iframe,
            video{
                @extend %overlay;
                width: 100%;
                height: 100%;
                z-index: -2;
                transform-origin: center;
                transform: scale(1.5);
                @include r(1023){
                    width: 300%;
                    left: 50%;
                    transform: translateX(-50%) scale(1.5);
                }
            }
        }
    }

    &__container {
        @extend %container_lg;
    }

    &__content {
        padding-top: 32px;
        // padding-bottom: 32px;
        padding-bottom: 47px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        position: relative;
        @include rmin(1200){
            padding-bottom: 60px;
        }
    }

    &__title {
        margin-top: auto;
    }

    &__title-text {

        @include media(xl, min) {
            font-size: 61.5/1366*100vw;
        }
        &.only-title{
            line-height: 1.5;
        }
        p{
            margin: 0;
        }
    }
    &__center{
        display: block;
        width: auto;
        margin: auto;
        max-width: 600px;
        text-align: center;
    }
    &__btn-video{
        position: absolute;
        width: 80px;
        height: 80px;
        top: calc(50% - 40px);
        left: calc(50% - 40px);
    }
    &__portfolio-columns{
        display: flex;
        @include r(767){
            flex-wrap: wrap;
        }
        &__inner{
            padding-top: 20px;
            flex: 0 1 auto;
            padding-right: 60px;
            font-weight: $bold-font-weight;
            letter-spacing: 0.03em;
            line-height: 1.2;
            @include r(767){
                padding-right: 15px;
                flex: 1 1 100%;
                max-width: 100%;

            }
            .top{
                margin-bottom: 12px;
                font-size: 14px;
                opacity: 0.8;
            }
            .bottom{
                font-size: 18px;
            }
        }
    }
    .arrow-btn{
        margin: 20px 0 10px;
        display: inline-flex;
        width: auto;
        flex: 0 0 auto;
        align-self: flex-start;
    }
}
.portfolio-inner-page .intro__content{
    @include r(767){
        padding-bottom: 40px;
    }
}
.page-404{

    .intro__content{
        justify-content: center;
        @media (max-width:1023px) and (orientation: landscape) {
            justify-content: flex-end;
        }
        @media (max-width:600px) and (orientation: landscape) {
            padding: 0 0 20px;
        }
    }
    .intro__bg{
        @include rIn(1024,1199){
            background-position: left 46% center;
        }
        @include r(1023){
            background-position: 0 0;
        }
        @media (max-width:600px) and (orientation: landscape) {
            background-position: 0 0;
        }
    }
}
.page-404__inner{
    @include rmin(1360){
        max-width: 40em;
        padding-left: 10em;
    }
    img{
        margin-bottom: 1em;
        width: 28vw;
        @include r(1920){
            max-width: 500px;
        }
        @include r(767){
            width: 280px;
        }

    }
    @media (min-width:1024px) and (max-height:700px) {
        img{
            max-height: 15vh;

            width: auto;
        }
        h3{
            font-size: 27px;
        }
    }
    @media (max-width:1023px) and (orientation: landscape) {
        max-width: 60%;
        img{
            margin-bottom: 0;
            max-height: 15vh;
            width: auto;
        }
        h3{
            font-size: 27px;
        }
    }
    @include rIn(1024,1199){
        max-width: 380px;
        h3{
            font-size: 27px;
        }
    }
    @include r(350){
        img{
            max-width: 200px;
        }
        h3{
            font-size: 22px;
        }
    }
    @media (max-width:600px) and (orientation: landscape) {
        max-width: 100%;
        h3{
            font-size: 22px;
        }
    }
}

.la-is-open {
    // .intro__bg{
    //     background-position-x: calc(100% - 230px);
    // }
    // & .layout__header{
    //     display: block;
    //     width: 100%;
    //     position: relative;
    //     margin-left: -230px;
    // }
    // @include r(1023){
    //     overflow: hidden !important;
    // }

}

@keyframes circleScale{
    0%{
        transform: translate(-50%, -50%) scale(0);
        opacity: 0;
    }
    50%{
        opacity: 1;
    }
    100%{
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }
}
@keyframes pulsingOnHover{
    0%{
        transform: translate(-50%, -50%) scale(0);
        opacity: 0;
    }
    3%{
        opacity: 1;
    }
    5%,8%,15%,23%,30%,37%,44%,51%,58%,65%,72%,79%,86%,93%,100%{
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }
    9%,16%,24%,31%,38%,45%,52%,59%,66%,73%,80%,87%,94%{
        transform: translate(-50%, -50%) scale( 1.1 );
    }
    10%,17%,25%,32%,39%,46%,53%,60%,67%,74%,81%,88%,95%{
        transform: translate(-50%, -50%) scale( 1.3 );
    }

}
@keyframes pulsingHeart{
    0%, 16%, 32%, 40%, 100%{
      transform: translate(-50%, -50%) scale( .95 );
    }
    8%,24%{
      transform: translate(-50%, -50%) scale( 1 );
    }
}
.btn-video{
    &__icon{
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        border-radius: 50%;
        background-color: white;
        z-index: 2;
        transition: transform 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        svg{
            width: 8px;
            height: 10px;
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }
        &:hover{
            transform: scale(.92);
            cursor: pointer;
        }
    }
    &__circle{
        fill: none;
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: none;
        max-height: none;
        transition: transform 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        transform-origin: 50% 50%;
        transform: translate(-50%, -50%) scale(1);

    }
}

.intro.active{
    .btn-video__circle{
        animation: circleScale 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s 1 forwards;
        &-2{
            animation-delay: .2s;
        }
        &-3{
            animation-delay: .4s;
        }
    }
    &.pulsingHeart{
        .btn-video__circle{
            animation: pulsingHeart 7s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s infinite forwards;
            &-2{
                animation-delay: .15s;
            }
            &-3{
                animation-delay: .3s;
            }
        }
    }
}



.intro__bgVideo {
    padding: 75px 0px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1100;
    background-color: #4a4a4a;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition: .2s ease-in-out;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    &.show {
        visibility: visible;
        opacity: 1;
        pointer-events: auto;
    }
    @include r(767){
        padding: 40px 0px;
    }
    .intro-bgVideo__close {
        position: absolute;
        top: 0;
        right: 0;
        padding: 20px;
        cursor: pointer;
        z-index: 10;
        transition: .2s ease-in-out;
        background-color: #0c1218;
        opacity: 1;
        #js-video-close{
            display: flex;
        }
        @include r(767){
            padding: 10px;
        }
        svg{
            width: 30px;
            height: 30px;
            @include r(767){
                width: 20px;
                height: 20px;
            }
        }
        &:hover {
            opacity: .6;
        }
    }
    .videoBg{
        // padding-bottom: 56.25%;
        width: 100%;
        height: 100%;
        position: relative;
        .js-frame-bgVideo{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }
}


