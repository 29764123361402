.about-page{
    .intro__title{
        @include abs-center();
        font-size: 365/2560*100vw;
        text-transform: uppercase;
        letter-spacing: .05em;
        user-select: none;
        white-space: nowrap;
        h1{
            font-size: inherit;
            letter-spacing: inherit;
        }
    }
    &__section{
        .container_lg{
            padding-top: 90px;
            padding-bottom: 90px;
            @include rIn(768,1023){
                padding-top: 45px;
                padding-bottom: 45px;
            }
            @include r(767){
                padding-top: 30px;
                padding-bottom: 0;
            }
        }
    }
    &__title{
        font-weight: $bold-font-weight;
        @include css-lock(24,48,768,1200);
        line-height: 58/48*1em;
        letter-spacing: 0.02em;
        color: $color-black;
        @include r(1199){
            br{
                &:not(.all-mq){
                    display: none;
                }
            }
        }
    }
    .about .right{
        .right__title::after{
            content: none;
        }
    }
    .intro__bg{
        background-position: center;
    }

}
.fullwidth-img{
    width: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    flex-flow: column wrap;
    img{
        width: 100%;
        height: auto;
        z-index: 0;
    }
    &__label{
        @include css-lock(35,65,768,1200, padding);
        position: absolute;
        right: 0;
        bottom: 0;
        width: auto;
        background-color: $color-white;
        @include css-lock(20,24,768,1200);
        line-height: 40/24*1em;
        &-decor{
            position: absolute;
            left: 0;
            top: 0;
            width: 115px;
            height: 115px;
            transform: translate(-50%,-50%);
        }
        @include r(767){
            margin-bottom: 20px;
            padding: 5px 20px 20px;
            position: static;
        }
    }
}

.craftsmen-section{
    overflow: hidden;
    background-image: linear-gradient(0deg, #201E1D, #201E1D);
    &__title{
        color: $color-white;
    }
    .container_lg{
        padding-top: 90px;
        padding-bottom: 90px;
        @include rIn(768,1023){
            padding-top: 45px;
            padding-bottom: 45px;
        }
        @include r(767){
            padding-top: 30px;
            padding-bottom: 0;
        }
    }
}


.craftsmen-list{
    // $clGap: var(--g-padding-x)/2;

    @extend %reset-list;
    position: relative;
    &::before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 112px;
        height: 115px;
        transform: translate(-40%,-50%) rotate(90deg);
        background-image: url('/assets/img/about/circle.svg');
        background-size: cover;
        background-repeat: no-repeat;
        transform-origin: 50% 50%;
    }
    @include r(767){
        flex-wrap: wrap;
        .slick-arrow{
            position: absolute;
            top: 50%;
            z-index: 2;
            width: 40px;
            height: 40px;
            overflow: hidden;
            text-indent: -999px;
            background-color: transparent;
            border-color: transparent;
            &:focus{
                outline: none;
            }
            &.slick-prev{
                left: 0;
                &::after{
                    content: '';
                    position: absolute;
                    width: 12px;
                    height: 22px;
                    top: 0;
                    left: 0;
                    background-image: url('/assets/img/about/arrow-left.svg');
                }
            }
            &.slick-next{
                right: 0;
                &::after{
                    content: '';
                    position: absolute;
                    width: 12px;
                    height: 22px;
                    top: 0;
                    right: 0;
                    background-image: url('/assets/img/about/arrow-right.svg');
                }
            }
        }
        &__item{
            flex: 1 1 100%;
            max-width: 100%;
            &:not(:first-child){
                display: none;
            }
        }
    }

    @include rmin(1024){
        margin-left: calc(var(--g-padding-x)/2 * -1);
        margin-right: calc(var(--g-padding-x)/2 * -1);
    }
    &__item{
        margin-bottom: 30px;
        position: relative;
        display: flex;

        @include rIn(768,1023){
            flex: 1 1 33.3333%;
            max-width: 33.3333%;
        }

        &:hover{
            img:not(.imgOnHover){
                opacity: 0;
            }
            .imgOnHover{
                opacity: 1;
            }
        }
        @include rIn(1024,1199){
            flex: 1 1 25%;
            max-width: 25%;
        }
        @include rmin(1200){
            flex: 1 1 20%;
            max-width: 20%;
        }
        @include rmin(1024){
            margin-bottom: var(--g-padding-x);
            padding-left: calc(var(--g-padding-x)/2);
            padding-right: calc(var(--g-padding-x)/2);
        }

        &::after{
            content: '';
            position: absolute;
            z-index: 2;
            top: 0;
            left: 15px;
            right: 15px;
            bottom: 0;
            background: linear-gradient(180deg, rgba(71, 67, 65, 0) 72.25%, #474341 100%);
            opacity: 0.5;
            pointer-events: none;
            @include rmin(1024){
                left: calc(var(--g-padding-x)/2);
                right: calc(var(--g-padding-x)/2);
            }
        }
        .name{
            position: absolute;
            bottom: 25px;
            left: 25px;
            font-weight: $bold-font-weight;
            font-size: 24px;
            line-height: 29px;
            letter-spacing: 0.03em;
            color: $color-white;
            z-index: 3;
            text-transform: capitalize;
        }
    }
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
        @extend %overlay;
        transition: opacity .8s ease;
        opacity: 1;
        &.imgOnHover{
            opacity: 0;
            z-index: 2;
        }
    }
    // canvas{
    //     z-index: 2;
    // }
    &__inner{
        display: block;
        width: 100%;
        overflow: hidden;
        @include aspect-ratio(240,266,true);

    }

}

.craftsmen-list__inner:hover{
    cursor: pointer;
}

.p-first-wm{
    p:first-child{
        margin-top: 0.8em;
    }
}

.distortion {
    width: 580px;
    height: 720px;
    position: absolute;
    right: 300px;
    bottom: 0;
  }