// Header
.header {
    position: absolute;
    top: 48px;
    left: 0;
    width: 100%;
    color: $color-white;
    @extend %container;
    z-index: z('header');
    @include rIn(768,1023){
        top: 24px;
    }
    @include r(767){
        top: 0;
    }
    &__container {
        @extend %flex-row;
        align-items: center;
        justify-content: space-between;
        height: 64px;
        @include r(767){
            height: 48px;
        }
    }
    @include rmin(768){
        .mobile{
            display: none;
        }
    }
    &__nav{
        .mobile{
            &_top{
                display: flex;
                width: 100%;
                height: 25vh;
                align-items: center;
                justify-content: center;
                .icon{
                    position: absolute;
                    top: 20px;
                    right: 20px;
                    svg{
                        stroke: $color-brown;
                    }
                }
            }
            .logo{
                width: 100px;
                height: 100px;
            }
        }
        @include r(767){
            position: fixed;
            width: 100vw;
            height: 100vh;
            top: 0;
            left: 0;
            z-index: 10;
            background-color: $color-white;
            color: $color-black;
            overflow: hidden;
            transition: all $transition-base;
            transform: translateY(100%);
            .menu-open &{
                transform: translateY(0);
            }
            .nav{
                position: relative;
                &::before{
                    content: '';
                    width: 300px;
                    height: 300px;
                    background-image: url(../img/svg/fingerprint.svg);
                    background-size: contain;
                    background-repeat: no-repeat;
                    bottom: 100%;
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%, -9%);
                    opacity: .03;
                    z-index: -1;
                }
                &__list{
                    display: block;
                    text-align: center;
                }
                &__item{
                    margin: 0 auto 10px;
                }
                &__link{
                    display: inline-block;
                    &::before{
                        height: 3px;
                    }
                    &.is-active{
                        color: $color-brown;
                    }
                }
            }
        }
        .desktop-hidden{
            @include rmin(768){
                display: none;
            }
        }
    }
    &.is-fixed {
        position: fixed;
        // background-color: $color-white;
        // color: $color-black;
        // transform: translateY(-48px);
    }
    &__portfolio-close{
        display: inline-flex;
        align-items: center;
        transition: $transition-base;
        text-decoration: none;
        color: $color-white;
        &:hover{
            cursor: pointer;
            opacity: .7;
        }
    }
    &__icon-close{
        width: 12px;
        height: 12px;
        @include abs-center();
    }
    &__icon-ellipse{
        width: 100%;
        height: 100%;
        @include abs-center();
    }
    .text{
        flex: 1;
        display: inline-block;
    }
    .icon{
        margin: 0;
        margin-left: 20px;
        width: 23px;
        height: 22px;
        display: inline-block;
        position: relative;
    }
    // &__logo{
    //     transform: scale(0.7);
    //     transform-origin: 0 50%;
    // }
}

.logo {
    margin-left: -6px;
    display: block;
    width: 112px;
    height: 112px;
    position: relative;
    border-radius: 50%;
    border: 6px solid transparent;
    @include rIn(768,1023){
        width: 75px;
        height: 75px;
        border-width: 4px;
    }
    @include r(767){
        margin-left: -4px;
        width: 50px;
        height: 50px;
        border-width: 4px;
        transform: translateY(10px);
    }
    img {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        &.logo-brown{
            opacity: 0;
        }
    }
}

.nav {

    &__list {
        @extend %flex-row;
        list-style: none;
        margin: 0;
        padding: 0;
    }

    &__item {
        display: block;
        margin-left: 32px;
    }

    &__link {
        display: block;
        color: inherit;
        font-size: 14px;
        line-height: 17/14*1em;
        font-weight: $bold-font-weight;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        text-decoration: none;
        position: relative;
        padding: 4px 0;
        @include r(767){
            font-size: 18px;
            line-height: 2em;
        }

        &::before {
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: currentColor;
            opacity: 0.6;
            transform: scaleX(0);
            transform-origin: right;
            transition: transform $transition-base;
            transition-duration: 0.6s;
        }

        &:hover,
        &.is-active{

            &::before {
                transform: scaleX(1);
                transform-origin: left;
            }
        }

    }
    &__portfolio-close{
        position: relative;
    }

}


// .header-visible--white{
//     .header__logo{
//         img.logo-white{
//             display: none;
//         }
//         img.logo-brown{
//             display: block;
//         }
//     }
// }

.layout__header{
    transition: all $transition-base;
}

body{
    &.menu-open{
        overflow: hidden !important;
    }
}

@include r(767){
    .mobile-trigger-nav{
        display: block;
        width: 20px;
        height: 15px;
        position: relative;
        .icon-trigger{
            position: absolute;
            width: 14px;
            height: 1px;
            color: $color-white;
            background-color: currentColor;
            top: 7px;
            .is-fixed &{
                color: $color-brown;
            }
            &::before,
            &::after{
                content: '';
                position: absolute;
                height: 1px;
                width: 20px;
                background-color: currentColor;
                right: 0;
            }
            &::before{
                top: -7px;
            }
            &::after{
                bottom: -7px;
            }
        }
    }
}