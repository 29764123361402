.partners{
    position: relative;
    &-section{
        background-image: url(../img/partners/partners-bg-bottom.jpg);
        background-size: 100% auto;
        background-position: 0 101%;
        background-repeat: no-repeat;
        background-color: $color-white;
        @include retina{
            background-image: url(../img/partners/partners-bg-bottom@2x.jpg);
        }
        h2{
            display: block;
            text-align: center;
            margin-bottom: 50px;
            @include r(767){
                margin-top: 50px;
                margin-bottom: 20px;
            }
        }
        .decor-fingerprint_pt{
            top: 0;
            left: 0;
            transform: translate(-50%, -50%);
        }
    }
    &__list{
        @extend %flex-row;
        @extend %reset-list;
        position: relative;
        flex-wrap: wrap;
    }
    li{
        padding: 25px;
        text-align: center;
        background-color: transparent;
        @include rIn(768,1023){
            max-width: 25%;
        }
        @include rIn(451,767){
            max-width: 33.3333%;
        }
        @include r(450){
            max-width: 50%;
        }
        // &:nth-child(odd){
        //     background-color: $color-white;
        //     box-shadow: 0px 32px 80px rgba(71, 67, 65, 0.03);
        // }
        // &:nth-child(8n + 8),
        // &:last-child{
        //     border: 1px solid rgba($color-black, .06);
        //     border-bottom-color: transparent;
        // }
        @include rmin(1024){
            flex: 1 1 16.66666%;
            max-width: 16.66666%;
        }
    }
    .decor-circle{
        &--hor{
            top: 40px;
            left: 95%;
            transform: translateY(-100%);
        }
        &--ver{
            bottom: 0;
            left: 58%;
            transform: translate(50%,50%);
            svg{
                fill: rgba($color-black, .06);
            }
        }
    }
}