// Scroll button
.scroll-btn {
    position: absolute;
    bottom: 0;
    right: 0;
    @extend %reset-btn;
    transform: rotate(-90deg) translateX(100%);
    transform-origin: bottom right;
    color: $color-black;
    margin-bottom: 48px;
    margin-right: var(--g-padding-x);
    z-index: 2;
    min-width: 190px;
    text-align: left;
    height: 32px;
    display: flex;
    align-items: center;
    @include rIn(768,1023){
        margin-right: 30px;
    }
    @include r(767){
        display: none;
    }
    &_light {
        color: $color-white;
    }

    > * {
        display: inline-block;
        vertical-align: middle;
        margin-left: 24px;
        backface-visibility: hidden;

        &:first-child {
            margin-left: 0;
        }
    }

    &__arrow{
        position: absolute;
        top: 13px;
        left: 14px;
        @include arr(4,6,currentColor,l);
        opacity: .6;
        transition: opacity .6s ease;
        .scroll-btn:hover &{
            opacity: 1;
        }
    }
    &__circles{
        circle{
            transition: all .6s ease;
        }
    }
    &__circle{
        position: absolute;
        top: 0;
        left: 0;
        &--base{
            transform: rotate(0deg);
            transition: all .6s ease;
            opacity: .6;
            circle{
                stroke-width: 1px;
                stroke: currentColor;
                fill: none;
                stroke-dasharray: 100;
                stroke-dashoffset: 32;
            }
            .scroll-btn:hover &{
                transform: rotate(-180deg);
                circle{
                    stroke-dashoffset: 10;
                }
            }

        }
        &--fill{
            opacity: .4;
            circle{
                stroke-width: 1px;
                stroke: currentColor;
                fill: none;
            }
        }
    }

    &__line {
        width: 56px;
        height: 1px;
        // border-bottom: 1px solid;
        background-color: currentColor;
        will-change: width;
        transition: width .6s ease;
        .scroll-btn:hover &{
            width: 28px;
        }
    }

    &__text {
        font-size: 12px;
        line-height: 14/12*1em;
        font-weight: $bold-font-weight;
        letter-spacing: 0.1em;
    }
}
