@font-face {
    font-family: 'ITC Lubalin Graph Std';
    src: url('../fonts/LubalinGraphStd-Book.woff2') format('woff2'),
    url('../fonts/LubalinGraphStd-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'ITC Lubalin Graph Std';
    src: url('../fonts/LubalinGraphStd-BookOblique.woff2') format('woff2'),
    url('../fonts/LubalinGraphStd-BookOblique.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'ITC Lubalin Graph Std';
    src: url('../fonts/LubalinGraphStd-Demi.woff2') format('woff2'),
    url('../fonts/LubalinGraphStd-Demi.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'ITC Lubalin Graph Std';
    src: url('../fonts/LubalinGraphStd-DemiOblique.woff2') format('woff2');
    font-weight: 600;
    font-style: italic;
}

